var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Inject, Vue } from "vue-property-decorator";
import * as bootstrap from 'bootstrap';
let default_1 = class default_1 extends Vue {
    constructor() {
        super(...arguments);
        this.modal = null;
        this.code = null;
    }
    mounted() {
        this.modal = new bootstrap.Modal(this.$el, { backdrop: 'static' });
    }
    show() {
        return new Promise(resolve => {
            this.code = null;
            this.$el.addEventListener('hidden.bs.modal', () => resolve(this.code), { once: true });
            this.modal.show();
        });
    }
    cancel() {
        this.code = null;
        this.modal.hide();
    }
};
__decorate([
    Inject()
], default_1.prototype, "alert", void 0);
default_1 = __decorate([
    Component
], default_1);
export default default_1;
