
	import { Component, Inject, Vue } from "vue-property-decorator";

	import { logout } from "@/views/login/Login";
	import { resetCache } from "@/views/report/Index.vue";
	import Action from "./components/Action.vue";
	import UserHeader from "./components/UserHeader.vue";

	import type { LeapaAccountModel } from "@/models/Info";

	@Component({ components: { Action, UserHeader } })
	export default class extends Vue {
		@Inject() public readonly confirm!: ConfirmMethod;

		protected data: LeapaAccountModel | null = null;

		mounted(): void {
			this.load();
		}

		private async load() {
			this.data = await post<LeapaAccountModel>("/api/Info");
		}

		protected async logout(): Promise<void> {
			if(await this.confirm("確定登出 LeaPA？", "登出", "warning")) {
				resetCache();
				logout();
			}
		}

		protected change(): void {
			this.$router.push('/info/changepassword');
		}

		protected changePin(): void {
			this.$router.push('/info/changePin');
		}

		protected delAccount(): void {
			this.$router.push('/info/delaccount');
		}

		protected lineAt(): void {
			window.open("https://lin.ee/kUcmeAE", "_blank");
		}

		protected scan(): void {
			this.$router.push('/info/scan');
		}
	}
