
	import { Component, Inject, Vue } from "vue-property-decorator";

	import { logout } from "../login/Login";
	import { resetCache } from "../report/Index.vue";

	import type { PasswordModel } from "@/models/Login";

	@Component
	export default class Page extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		protected data: PasswordModel = {
			password: '',
			confirmPassword: '',
		};

		protected async send(): Promise<void> {
			await post('/api/Info/ChangePassword', {
				Password: this.data.password,
				ConfirmPassword: this.data.confirmPassword,
			});
			await this.alert("請重新使用新的密碼登入", "修改成功", "success");
			resetCache();
			logout();
		}
	}
