/**
 * 智慧型遮蔽字串，指定要遮蔽的字元以及頭尾希望保留的長度，如果字串長度不足會自動設法產生合理結果。
 */
export function mark(text, symbol, head, tail) {
    if (!text)
        return text;
    if (!symbol)
        symbol = "*";
    const l = text.length;
    while (head + tail + 1 > l && (head || tail)) {
        if (tail > 0)
            tail--;
        else
            head--;
    }
    return text.substring(0, head) + symbol.repeat(l - head - tail) + text.substring(l - tail);
}
