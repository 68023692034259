var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue } from "vue-property-decorator";
let TicketContent = class TicketContent extends Vue {
    get showInit() {
        const settings = this.data.Promotion.Settings;
        // 20230225: 如果尚未領取，隨機型的票券要顯示區間
        return Boolean(this.data.ReceiveTime) || settings.MaxActivationCount == settings.MinActivationCount;
    }
};
__decorate([
    Prop()
], TicketContent.prototype, "data", void 0);
TicketContent = __decorate([
    Component
], TicketContent);
export default TicketContent;
