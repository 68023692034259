
	import { Component, Inject, Vue } from "vue-property-decorator";
	import { logout } from "../login/Login";

	@Component
	export default class extends Vue {
		@Inject() public readonly alert!: AlertMethod;

		protected agree: boolean = false;
		protected password: string = "";

		protected async send(): Promise<void> {
			await post('/api/Info/DeleteAccount', { Id: this.password });
			this.alert('帳戶已刪除');
			logout();
		}
	}
